import { Transition } from "@headlessui/react";
import { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { ClientSide } from "~/@types";
import { IInsight } from "~/@types/generated/contentful";
import { CircleIconCta, IconCta } from "../ctas";
import { Email, Facebook, Linkedin, Share, Twitter } from "../icons";

type ShareButtonProps = {
  insight: ClientSide<IInsight>;
};

export default function ShareButton({ insight }: ShareButtonProps) {
  const [isShowing, setIsShowing] = useState(false);

  const currentUrl =
    typeof window !== "undefined" &&
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1"
      ? window.location.href
      : `https://www.institute.global/insights/${insight.fields.topic.fields.slug}/${insight.fields.slug}`;
  const emailSubject = insight.fields.title
    ? insight.fields.title.substring(0, 60)
    : "";
  const emailBody = insight.fields.title;
  const twitterTitle = `${insight.fields.title} via @InstituteGC`;

  return (
    <>
      <div
        data-component-type="open-share-buttons"
        data-module-title={insight.fields.title}
        onMouseOver={() => setIsShowing(true)}
        onFocus={() => setIsShowing(true)}
        onMouseOut={() => setIsShowing(false)}
        onBlur={() => setIsShowing(false)}
        className="mb-4 flex flex-row-reverse justify-around rounded-full border border-solid border-dark_grey bg-white lgmax:hidden"
      >
        <CircleIconCta
          type="button"
          kind="white"
          icon={<Share />}
          label="Share"
        />
        <Transition
          className="flex justify-around overflow-hidden"
          show={isShowing}
          enter="transition-[width, opacity] duration-300"
          enterFrom="max-w-[0] opacity-0"
          enterTo="max-w-[260px] opacity-100"
          leave="transition-[width, opacity] duration-300"
          leaveFrom="max-w-[260px] opacity-100"
          leaveTo="max-w-[0] opacity-0"
        >
          <EmailShareButton
            data-component-type="email-share-button"
            data-module-title={insight.fields.title}
            url={currentUrl}
            subject={emailSubject}
            body={emailBody}
          >
            <CircleIconCta
              as="div"
              kind="white"
              icon={<Email />}
              label="Share with Email"
            />
          </EmailShareButton>
          <FacebookShareButton
            data-component-type="facebook-share-button"
            data-module-title={insight.fields.title}
            url={currentUrl}
          >
            <CircleIconCta
              as="div"
              kind="white"
              icon={<Facebook />}
              label="Share with Facebook"
            />
          </FacebookShareButton>
          <TwitterShareButton
            data-component-type="twitter-share-button"
            data-module-title={insight.fields.title}
            url={currentUrl}
            title={twitterTitle}
          >
            <CircleIconCta
              as="div"
              kind="white"
              icon={<Twitter />}
              label="Share with X"
            />
          </TwitterShareButton>
          <LinkedinShareButton
            data-component-type="linkedin-share-button"
            data-module-title={insight.fields.title}
            url={currentUrl}
            source={currentUrl}
          >
            <CircleIconCta
              as="div"
              kind="white"
              icon={<Linkedin />}
              label="Share with LinkedIn"
            />
          </LinkedinShareButton>
        </Transition>
      </div>
      <div className="lg:hidden">
        <IconCta
          kind="black"
          type="button"
          icon={<Share />}
          label="Share"
          onClick={() => setIsShowing((isShowing) => !isShowing)}
        />
        <Transition
          className="absolute bottom-[100%] left-0 right-0 overflow-hidden bg-white"
          show={isShowing}
          enter="transition-height duration-300"
          enterFrom="max-h-[1px]"
          enterTo="max-h-[50px]"
          leave="transition-height duration-300"
          leaveFrom="max-h-[50px]"
          leaveTo="max-h-[1px]"
        >
          <div className="flex justify-around border-t border-dark_grey pt-4">
            <EmailShareButton
              data-component-type="email-share-button"
              data-module-title={insight.fields.title}
              url={currentUrl}
              subject={emailSubject}
              body={emailBody}
            >
              <IconCta
                as="div"
                kind="black"
                icon={<Email />}
                label="Share with Email"
              />
            </EmailShareButton>
            <FacebookShareButton
              data-component-type="facebook-share-button"
              data-module-title={insight.fields.title}
              url={currentUrl}
            >
              <IconCta
                as="div"
                kind="black"
                icon={<Facebook />}
                label="Share with Facebook"
              />
            </FacebookShareButton>
            <TwitterShareButton
              data-component-type="twitter-share-button"
              data-module-title={insight.fields.title}
              url={currentUrl}
              title={twitterTitle}
            >
              <IconCta
                as="div"
                kind="black"
                icon={<Twitter />}
                label="Share with Twitter"
              />
            </TwitterShareButton>
            <LinkedinShareButton
              data-component-type="linkedin-share-button"
              data-module-title={insight.fields.title}
              url={currentUrl}
              source={currentUrl}
            >
              <IconCta
                as="div"
                kind="black"
                icon={<Linkedin />}
                label="Share with LinkedIn"
              />
            </LinkedinShareButton>
          </div>
        </Transition>
      </div>
    </>
  );
}
