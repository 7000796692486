import { useRef } from "react";
import { useEffectOnce } from "react-use";
import {
  IHtmlStringFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import Flourish from "./Flourish";
import InsightVideoPlayer from "./InsightVideoPlayer";
import SoundCloudEmbedded from "./SoundCloudEmbedded";

type HtmlStringProps = {
  fields: SpecificLocaleFields<IHtmlStringFields>;
  beforeSecondChapter?: boolean;
};

export default function HtmlString({
  fields,
  beforeSecondChapter = false,
}: HtmlStringProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    if (ref.current) {
      ref.current.innerHTML = fields.html;
    }
  });

  const renderHtml = () => {
    switch (fields.type) {
      case "flourish":
        return (
          <Flourish
            className="col-span-full pt-10"
            html={fields.html}
            beforeSecondChapter={beforeSecondChapter}
          />
        );
      case "video":
        return <InsightVideoPlayer url={fields.html} />;
      case "audio":
        return <SoundCloudEmbedded fields={fields} />;
      case "highchart":
      case "svg":
      case "style":
      case "script":
      case "embed":
      default:
        return <div className="flex-center col-span-full" ref={ref} />;
    }
  };
  return (
    <div
      data-content-type="htmlString"
      data-module-title={fields.moduleTitle}
      className="article-columns-max flex-center global-grid relative"
    >
      {renderHtml()}
      {beforeSecondChapter && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      )}
    </div>
  );
}
