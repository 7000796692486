import type { CurrentRefinementsConnectorParamsItem } from "instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements";
import { useCallback, useEffect, useReducer } from "react";
import {
  useCurrentRefinements,
  useInstantSearch,
} from "react-instantsearch-hooks";
import { ALGOLIA_FACETS } from "~/config";
import { useNumResults } from "./useNumResults";

type MenuState = {
  topics: string[];
  regions: string[];
  types: string[];
  years: string[];
};

const emptyState: MenuState = {
  topics: [],
  regions: [],
  types: [],
  years: [],
};

const refinementsToMenuState = (
  items: CurrentRefinementsConnectorParamsItem[]
): MenuState => ({
  topics: extractRefinementValues(items, ALGOLIA_FACETS.insights.topic) ?? [],
  regions: extractRefinementValues(items, ALGOLIA_FACETS.insights.region) ?? [],
  types: extractRefinementValues(items, ALGOLIA_FACETS.insights.type) ?? [],
  years: extractRefinementValues(items, ALGOLIA_FACETS.insights.year) ?? [],
});

const menuStateToRefinements = (state: MenuState) => ({
  ...(state.topics && {
    [ALGOLIA_FACETS.insights.topic]: state.topics,
  }),
  ...(state.regions && {
    [ALGOLIA_FACETS.insights.region]: state.regions,
  }),
  ...(state.types && {
    [ALGOLIA_FACETS.insights.type]: state.types,
  }),
  ...(state.years && {
    [ALGOLIA_FACETS.insights.year]: state.years,
  }),
});

const menuStateToFacetFilters = (state: MenuState) => {
  return [
    state.topics.map((value) => `${ALGOLIA_FACETS.insights.topic}:${value}`),
    state.regions.map((value) => `${ALGOLIA_FACETS.insights.region}:${value}`),
    state.types.map((value) => `${ALGOLIA_FACETS.insights.type}:${value}`),
    state.years.map((value) => `${ALGOLIA_FACETS.insights.year}:${value}`),
  ];
};

// type BaseState = Record<string, string[]>;

type UpdateAction = {
  type: "add" | "remove" | "replace";
  facet: keyof MenuState;
  value: string;
};
type SetAllAction = {
  type: "set-all";
  state: MenuState;
};
type ClearAction = { type: "clear" };
export type Action = UpdateAction | SetAllAction | ClearAction;

function makeMenuStateReducer(cleanState: MenuState = emptyState) {
  return function menuStateReducer(state: MenuState, action: Action) {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.facet]: [...state[action.facet], action.value],
        };
      case "remove":
        return {
          ...state,
          [action.facet]: state[action.facet].filter(
            (value) => value !== action.value
          ),
        };
      case "replace": {
        return {
          ...state,
          [action.facet]: [action.value],
        };
      }
      case "set-all": {
        return action.state;
      }
      case "clear": {
        return cleanState;
      }
    }
  };
}

type UseFilterStateProps = {
  cleanState?: MenuState;
};

export function useFilterState({ cleanState }: UseFilterStateProps = {}) {
  const instantSearch = useInstantSearch();
  const { items: appliedRefinements } = useCurrentRefinements();
  const [menuState, dispatchMenuAction] = useReducer(
    makeMenuStateReducer(cleanState),
    refinementsToMenuState(appliedRefinements)
  );

  useEffect(() => {
    // Updates the UI when the Algolia state changes,
    // like when Sentence Search filters are applied
    // Or a reset
    dispatchMenuAction({
      type: "set-all",
      state: refinementsToMenuState(appliedRefinements),
    });
  }, [appliedRefinements]);

  const numFiltersChecked = Object.values(menuState).flat().length;
  const numFiltersApplied = Object.values(
    instantSearch.indexUiState.refinementList ?? {}
  ).flat().length;

  const clearFilters = () => {
    dispatchMenuAction({ type: "clear" });
  };

  const handleChange =
    (facet: keyof MenuState) => (value: string, checked: boolean) => {
      dispatchMenuAction({
        facet,
        value,
        type: checked ? "add" : "remove",
      });
    };

  const handleSubmit = useCallback(() => {
    instantSearch.setIndexUiState((indexState) => {
      return {
        ...indexState,
        refinementList: {
          ...indexState.refinementList,
          ...menuStateToRefinements(menuState),
        },
      };
    });
  }, [instantSearch, menuState]);

  const numResults = useNumResults(menuStateToFacetFilters(menuState));

  return {
    menuState,
    numFiltersChecked,
    numFiltersApplied,
    numResults,
    clearFilters,
    handleChange,
    handleSubmit,
  };
}

export const extractRefinementValues = (
  items: CurrentRefinementsConnectorParamsItem[],
  facetName: string
) =>
  items
    .find((item) => item.attribute === facetName)
    ?.refinements.map((r) => String(r.value));
