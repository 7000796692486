import { ITopic } from "~/@types/generated/contentful";
import {
  AnimatePresence,
  motion,
  useAnimationControls,
  useScroll,
  useTransform,
} from "framer-motion";
import { useEffectOnce, useWindowSize } from "react-use";
import clsx from "clsx";
import FlutterText from "../animations/flutterText";
import {
  ContentfulCenteredImage,
  ForcedAspectRatio,
  ImageSlowScroll,
} from "../shared";
import { ClientSide } from "~/@types";
import { PAGE_TRANSITION_DELAY } from "~/contexts/PageTransitionProvider";
import { useState } from "react";

type TopicHeroProps = {
  topic: ClientSide<ITopic>;
  total?: number;
};

const positions = [
  {
    horizontal: true,
    left: 4,
    top: 20,
  },
  {
    horizontal: false,
    left: 1,
    top: 0,
  },
  {
    horizontal: false,
    left: 0,
    top: -10,
  },
  {
    horizontal: true,
    left: -2,
    top: -2,
  },
  {
    horizontal: false,
    left: -5,
    top: -20,
  },
];

export default function TopicHero({ topic, total }: TopicHeroProps) {
  const { height } = useWindowSize();
  const { scrollY } = useScroll();
  const opacityTransform = useTransform(scrollY, [0, height * 0.75], [1, 0]);
  const [showHeading, setShowHeading] = useState(false);

  const imageControls = useAnimationControls();

  const sequence = () => {
    setTimeout(() => setShowHeading(true), PAGE_TRANSITION_DELAY);
    imageControls.start((ix) => ({
      left: `${positions[ix].left}%`,
      top: `${positions[ix].top}%`,
      opacity: 1,
      scale: 1,
      transition: {
        opacity: {
          delay: ix * 0.1 + 0.3 + PAGE_TRANSITION_DELAY / 1000,
          ease: "easeOut",
          duration: 0.7,
        },
        left: {
          delay: ix * 0.1 + PAGE_TRANSITION_DELAY / 1000,
          ease: "easeOut",
          duration: 0.5,
        },
        top: {
          delay: ix * 0.1 + PAGE_TRANSITION_DELAY / 1000,
          ease: "easeOut",
          duration: 0.5,
        },
        scale: {
          delay: ix * 0.1 + PAGE_TRANSITION_DELAY / 1000,
          ease: "easeOut",
          duration: 0.7,
        },
      },
    }));
  };

  useEffectOnce(() => {
    sequence();
  });

  const scrollToContent = () => {
    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };

  return (
    <section className="relative mx-auto h-full w-screen">
      <div className={clsx("relative z-10 flex h-screen w-screen")}>
        {/* // height is screen minus space for bottom explore eyebrow */}
        <div className="absolute left-0 top-mobile-nav flex h-[calc(100vh-44px-80px)] w-full justify-center lg:top-desktop-nav lg:mx-[36px] lg:h-[calc(100vh-44px-96px)] lg:w-[calc(100vw-72px)]">
          <AnimatePresence>
            <motion.div
              key="text"
              className="absolute inset-0 z-10 flex h-full w-full items-center justify-center"
              style={{
                opacity: opacityTransform,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.7,
              }}
            >
              <div className="absolute inset-0 z-10 flex h-full w-full flex-col justify-center text-white">
                <div className="flex w-full flex-col items-center justify-center">
                  <p className="eyebrows col-span-full mb-6 text-center lg:col-span-4 lg:col-start-5">
                    {total} insights
                  </p>
                  {showHeading && (
                    <FlutterText
                      text={topic.fields.title}
                      className="display col-span-full max-w-[1400px] text-center lg:col-span-8 lg:col-start-3"
                    />
                  )}
                </div>
              </div>
            </motion.div>
            <motion.div
              key="bg"
              className="absolute inset-0 flex h-full w-full items-center justify-center"
              style={{
                opacity: opacityTransform,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.7,
              }}
            >
              <div
                className="absolute inset-0 flex h-full w-full"
                style={{
                  backgroundColor: topic.fields.color,
                }}
              />
            </motion.div>
          </AnimatePresence>
          <div className="absolute inset-0 flex h-full w-full items-center justify-center overflow-hidden">
            <div className="flex-center relative h-[300px] w-screen 2xl:w-[80vw]">
              {topic.fields.heroImages?.map((image, ix, all) => (
                <motion.div
                  key={image.sys.id + ix}
                  className="relative"
                  style={{
                    zIndex: ix,
                  }}
                  initial={{
                    left: `${positions[ix].left}%`,
                    top: `${positions[ix].top}%`,
                    opacity: 0,
                    scale: 0.6,
                  }}
                  custom={ix}
                  animate={imageControls}
                >
                  <AnimatePresence>
                    <motion.div
                      className="h-full w-full"
                      initial={{ opacity: 1 }}
                      animate={{
                        opacity: ix === 0 || ix === all.length - 1 ? 1 : 0,
                      }}
                      transition={{
                        delay: ix * 0.2 + 0.4 + PAGE_TRANSITION_DELAY / 1000,
                      }}
                    >
                      <ForcedAspectRatio
                        ratio={positions[ix].horizontal ? [16, 9] : [3, 4]}
                        className={clsx({
                          "h-[70px] lg:h-[200px] 2xl:h-[260px]":
                            positions[ix].horizontal,
                          "w-[70px] lg:w-[200px] 2xl:w-[260px]":
                            !positions[ix].horizontal,
                        })}
                      >
                        <ImageSlowScroll>
                          <ContentfulCenteredImage
                            image={image.fields}
                            className="h-full w-full"
                          />
                        </ImageSlowScroll>
                      </ForcedAspectRatio>
                    </motion.div>
                  </AnimatePresence>
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-center absolute bottom-0 z-10 col-span-full h-10 w-full">
          <motion.button
            className="min-w-max text-14 uppercase"
            style={{
              opacity: opacityTransform,
            }}
            onClick={scrollToContent}
            whileHover="hover"
            whileInView="noHover"
          >
            Explore
            <motion.div
              className="flex border-b border-solid border-black content-['']"
              variants={{
                hover: {
                  transform: "scaleX(1)",
                },
                noHover: {
                  transform: "scaleX(0)",
                },
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
            />
          </motion.button>
        </div>
      </div>
    </section>
  );
}
