import { useFetcher } from "@remix-run/react";
import { EntryCollection } from "contentful";
import chunk from "lodash/chunk";
import qs from "qs";
import { useEffectOnce } from "react-use";
import {
  IInsightFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { fallbackLanguage } from "~/i18n-config";
import { contentfulInsightGetters } from "~/utils";
import ColumnCards from "./ColumnCards";

type InsightCardGroupFetcherProps = {
  linksTo?: string;
  excludeSlug: string[];
  skip: number;
};

export function InsightCardGroupFetcher({
  linksTo,
  excludeSlug,
  skip,
}: InsightCardGroupFetcherProps) {
  const insightsFetcher =
    useFetcher<EntryCollection<SpecificLocaleFields<IInsightFields>>>();

  useEffectOnce(() => {
    insightsFetcher.submit(
      new URLSearchParams(
        qs.stringify({
          linksTo,
          excludeSlug,
          skip,
          locale: fallbackLanguage,
        })
      ),
      {
        action: "/api/insights",
        method: "get",
        preventScrollReset: true,
      }
    );
  });

  if (!insightsFetcher.data) return null;

  return (
    <>
      {chunk(insightsFetcher.data.items, 3).map((chunk) => (
        <ColumnCards
          key={chunk[0].sys.id}
          insights={chunk.map(contentfulInsightGetters)}
        />
      ))}
    </>
  );
}
