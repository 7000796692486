import { useFetchers, useLocation, useTransition } from "@remix-run/react";
import { useContext, useMemo } from "react";
import { useSearchParams } from "../utils/useSearchParams";
import ListView from "./ListView";
import {
  IExpert,
  IInsight,
  IInsightFields,
  IInsightsList,
  IInsightsListFields,
  ITag,
  SpecificLocale,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import {
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import FeaturedInsightCard from "./FeaturedInsightCard";
import { ColumnCards } from ".";
import chunk from "lodash/chunk";
import { ClientSide } from "~/@types";
import { EntryCollection } from "contentful";
import { TextCta } from "../ctas";
import QueryString from "qs";
import { INSIGHT_CARD_GROUP_SIZE } from "~/config";
import { InsightCardGroupFetcher } from "./InsightCardGroupFetcher";
import { contentfulInsightGetters } from "~/utils";
import { StorybookContext } from "../utils/StorybookContext";

type CardListProps = {
  insightsListFields?: ClientSide<IInsightsListFields>;
  relatedEntry: ClientSide<IInsightsList | ITag | IExpert>;
  featuredInsight?: ClientSide<SpecificLocale<IInsight>>;
};

// Defined here to avoid re-creating the array on every render
// which would cause the useMemo to re-run
const emptyArray = [] as ClientSide<IInsight>[];

/**
 * Renders a list of INSIGHT cards. Used for
 * things like insightsList, expert page, tag page.
 *
 * `relatedEntry` is used to load the correct list
 * if it needs to be filtered by tag/expert.
 */
export default function CardList({
  insightsListFields,
  featuredInsight,
  relatedEntry,
}: CardListProps) {
  const isStorybook = !!useContext(StorybookContext);
  const location = useLocation();
  const searchParams = useSearchParams();
  const listView = searchParams.view?.includes("list");
  const page = Number(searchParams.page?.[0] ?? 1);

  const { insightsList, totalInsights } =
    useComponentData() as PageComponentData;
  const initialInsights = insightsList?.[relatedEntry.sys.id] ?? emptyArray;
  const groups = useMemo(
    () => chunk(initialInsights, INSIGHT_CARD_GROUP_SIZE),
    [initialInsights]
  );

  if (!initialInsights || !totalInsights) {
    console.warn(
      "The loader did not return insights or totalInsights for this page."
    );
  }

  const transition = useTransition();
  const fetchers = useFetchers();
  const allFetcherData = fetchers.flatMap(
    (f) =>
      (f.data as EntryCollection<SpecificLocaleFields<IInsightFields>>)
        ?.items ?? []
  ) as ClientSide<IInsight>[];

  const totalOnPage =
    (insightsListFields?.skip || 0) +
    initialInsights.length +
    allFetcherData.length +
    (featuredInsight ? 1 : 0);
  const endOfList = totalOnPage >= totalInsights!;

  const renderCardList = () => {
    if (listView && initialInsights) {
      const allInsights = [
        ...(featuredInsight ? [featuredInsight] : []),
        ...initialInsights,
        ...allFetcherData,
      ];
      return <ListView insights={allInsights.map(contentfulInsightGetters)} />;
    }

    return (
      <>
        {featuredInsight && (
          <FeaturedInsightCard fields={featuredInsight.fields} />
        )}
        {Array.from({ length: page }).map((_, i) => {
          // First render data from the loader
          const loadedGroup = groups[i];
          if (loadedGroup) {
            return chunk(loadedGroup, 3).map((chunk) => (
              <ColumnCards
                key={chunk[0].sys.id}
                insights={chunk.map(contentfulInsightGetters)}
              />
            ));
          }

          if (insightsListFields?.hideShowMoreButton) return null;

          // If we need to render a "page"/group who's data
          // didn't come from the loader, render a fetcher
          // to get the data.
          const excludeSlugs =
            insightsListFields?.excludeInsights?.map((i) => i.sys.id) ?? [];
          if (featuredInsight) {
            excludeSlugs.push(featuredInsight.sys.id);
          }
          const linksTo =
            relatedEntry.sys.contentType.sys.id === "insightsList"
              ? undefined
              : relatedEntry.sys.id;
          return (
            <InsightCardGroupFetcher
              key={i}
              linksTo={linksTo}
              excludeSlug={excludeSlugs}
              skip={
                (insightsListFields?.skip ?? 0) + i * INSIGHT_CARD_GROUP_SIZE
              }
            />
          );
        })}
      </>
    );
  };

  const searchParamChanging =
    transition.location?.pathname === location.pathname &&
    transition.state !== "idle";
  const fetchersLoading = fetchers.some((f) => f.state !== "idle");

  const renderShowMoreButton = () => {
    return (
      <div className="my-24 flex w-full items-center justify-center lg:mt-20 lg:mb-32">
        <div className="flex-center relative">
          <TextCta
            to={QueryString.stringify(
              {
                page: page + 1,
                view: searchParams.view?.[0],
              },
              { addQueryPrefix: true }
            )}
            kind="black"
            label={endOfList ? "End of list" : "Show more"}
            disabled={endOfList || fetchersLoading || searchParamChanging}
            className="p-4"
            navLinkProps={{
              preventScrollReset: true,
              reloadDocument: false,
              replace: true,
            }}
          />
          {fetchersLoading ||
            (searchParamChanging && (
              <svg
                className="absolute left-full h-6 w-6 animate-spin text-secondary_text"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div
      data-content-type="insightsList"
      data-module-title={
        insightsListFields?.moduleTitle ??
        (relatedEntry as ClientSide<ITag>).fields.slug
      }
    >
      {renderCardList()}
      {insightsListFields?.hideShowMoreButton || isStorybook
        ? null
        : renderShowMoreButton()}
    </div>
  );
}
