import {
  useInfiniteHits,
  useInstantSearch,
  useRefinementList,
} from "react-instantsearch-hooks-web";
import { ALGOLIA_FACETS } from "~/config";
import { ColumnCards, ListView } from "../Cards";
import { Hit as AlgoliaHit } from "instantsearch.js/es/types";
import { AlgoliaInsight, ClientSide } from "~/@types";
import { useMemo } from "react";
import chunk from "lodash/chunk";
import { algoliaInsightGetters } from "~/utils";
import { TextCta } from "../ctas";
import { ITopic } from "~/@types/generated/contentful";
import { useSearchParams } from "../utils/useSearchParams";
import FloatingListPageNav from "./FloatingListPageNav";

type HitType = AlgoliaHit<AlgoliaInsight>;

type FilteredInsightsListProps = {
  topic: ClientSide<ITopic>;
};

export default function FilteredInsightsList({
  topic,
}: FilteredInsightsListProps) {
  const searchParams = useSearchParams();
  const listView = searchParams.view?.includes("list");

  const instantSearch = useInstantSearch();
  const { hits, showMore, isLastPage, sendEvent } = useInfiniteHits<HitType>();

  useRefinementList({ attribute: ALGOLIA_FACETS.insights.topic });
  useRefinementList({ attribute: ALGOLIA_FACETS.insights.region });
  useRefinementList({ attribute: ALGOLIA_FACETS.insights.type });
  useRefinementList({ attribute: ALGOLIA_FACETS.insights.year });

  const groups = useMemo(
    () => chunk(hits.map(algoliaInsightGetters), 3),
    [hits]
  );

  const renderCardView = () => {
    return (
      <section className="mt-10">
        {groups.map((insightGroup) => (
          <ColumnCards
            key={insightGroup[0].getId()}
            insights={insightGroup}
            onClick={(insight) =>
              sendEvent(
                "click",
                insight.original() as HitType,
                "Insight card click"
              )
            }
          />
        ))}
      </section>
    );
  };

  const renderListView = () => {
    // TODO: add algolia click tracking to match card view above
    return <ListView insights={hits.map(algoliaInsightGetters)} />;
  };

  return (
    <div
      data-content-type="insightsList"
      data-module-title={topic.fields.slug}
      className="relative"
    >
      <FloatingListPageNav />
      {listView ? renderListView() : renderCardView()}
      <div className="flex-center py-20">
        <div className="flex-center relative pr-2">
          {isLastPage ? (
            <TextCta label={"end of list"} kind={"black"} disabled />
          ) : (
            <TextCta label={"show more"} kind={"black"} onClick={showMore} />
          )}

          {(instantSearch.status === "loading" ||
            instantSearch.status === "stalled") && (
            <svg
              className="absolute left-full h-6 w-6 animate-spin text-secondary_text"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}
