import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import { ClientSide } from "~/@types";
import { IInsight } from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import FootnotesList from "./FootnotesList";
import InsightNav from "./InsightNav";
import Tags from "./Tags";
import Standfirst from "./Standfirst";

type InsightComponentProps = {
  insight: ClientSide<IInsight>;
  url: string;
};

export default function InsightComponent({
  insight,
  url,
}: InsightComponentProps) {
  return (
    <article
      itemScope
      itemType="https://schema.org/Article"
      id={url}
      className="relative overflow-x-clip"
      data-content-type="insight"
      data-module-title={insight.fields.slug}
    >
      <InsightNav insight={insight} />
      {insight.fields.body && (
        <div className="tbi-grid pb-10" itemProp="articleBody">
          {insight.fields.standfirst && (
            <Standfirst {...insight.fields.standfirst.fields} />
          )}
          {documentToReactComponents(
            insight.fields.body as ContentfulDocument,
            getComponentFromContentfulRichTextEntry
          )}
        </div>
      )}
      <FootnotesList />
      <Tags tags={insight.fields.tags?.map((t) => t?.fields).filter(Boolean)} />
    </article>
  );
}
