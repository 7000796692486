import clsx from "clsx";
import {
  IPartnerRoutingFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";
import ContentfulImg from "../shared/ContentfulImg";

type PartnerRoutingProps = {
  fields: SpecificLocaleFields<IPartnerRoutingFields>;
};

export default function PartnerRouting({ fields }: PartnerRoutingProps) {
  return (
    <div
      data-content-type="partnerRouting"
      data-module-title={fields.moduleTitle}
      className="w-full bg-slate"
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className="global-container relative pt-24 pb-12 sm:pt-32 sm:pb-16">
        <div className="mb-10 flex flex-wrap items-center gap-x-[15px] gap-y-4">
          <p className="eyebrows w-[156px] sm:w-[232px]">OUR PARTNERS</p>
          {fields.images.map((image) => (
            <div
              key={image.sys.id}
              className={clsx(
                "flex-center flex h-[74px] rounded-[4px] bg-dark_grey p-[15px] sm:h-[108px] sm:p-6"
              )}
            >
              <ContentfulImg
                image={image.fields}
                className={clsx("flex-center h-full w-full")}
              />
            </div>
          ))}
        </div>
        {fields.button ? (
          <RoundedCta
            className="m-auto"
            kind="black"
            label={fields.button.fields.buttonText}
            to={fields.button.fields.buttonUrl}
            icon={<Arrow />}
            custom={fields.button.fields.colorPalette?.fields}
          />
        ) : fields.buttonText && fields.buttonUrl ? (
          <RoundedCta
            className="m-auto"
            kind="black"
            label={fields.buttonText}
            to={fields.buttonUrl}
            icon={<Arrow />}
          />
        ) : null}
      </div>
    </div>
  );
}
