import clsx from "clsx";
import { useConfigure, useInstantSearch } from "react-instantsearch-hooks-web";
import { Arrow } from "~/components/icons";
import { ALGOLIA_INDICES } from "~/config";
import { TextCta } from "../ctas";
import { AlgoliaProvider, AlgoliaSearchInput } from "../search";
import { Hit as AlgoliaHit, UiState } from "instantsearch.js/es/types";
import qs from "qs";
// TODO: delete this
// import SearchBar from "../search/SearchBar";
type HitType = AlgoliaHit<{ query: string }>;

export type FourOFourCaughtData = {
  backgroundColor: "black" | "white";
  notFound: string;
};

type FourOFourProps = {
  whatsNotFound?: string;
  backgroundColor?: "black" | "white";
};

export function FourOFour({
  whatsNotFound = "page",
  backgroundColor = "white",
}: FourOFourProps) {
  const instantSearch = useInstantSearch();
  const hits: HitType[] = instantSearch.results?.hits ?? [];

  useConfigure({
    hitsPerPage: 1,
  });

  const searchQueryParam = qs.stringify(
    {
      [ALGOLIA_INDICES.insights]: {
        query: instantSearch.indexUiState.query,
      },
    } as UiState,
    {
      encode: false,
    }
  );

  return (
    <div
      className={clsx({
        "h-full": true,
        "px-mobile-gutter": true,
        "pt-40": true,
        "pb-32": true,
        "lg:px-desktop-gutter": true,
        "lg:pt-52": true,
        "bg-white": backgroundColor === "white",
        "bg-black": backgroundColor !== "white",
      })}
    >
      <div className="m-auto max-w-[600px]">
        <h3
          className={clsx({
            "text-center": true,
            "text-white": backgroundColor !== "white",
          })}
        >
          The {whatsNotFound} you're looking for cannot be found.
        </h3>
        <div className="global-container mt-10 flex w-full justify-center">
          <AlgoliaSearchInput
            autofocus
            suggestion={hits?.[0]?.query}
            formAction="/search"
            inputName={searchQueryParam}
          />
        </div>
        <div className="mt-52 flex w-full justify-center">
          <TextCta
            kind={backgroundColor === "white" ? "black" : "white"}
            icon={<Arrow />}
            to="/"
            label="Back to home"
          />
        </div>
      </div>
    </div>
  );
}

const FourOFourWithAlgolia = (props: FourOFourProps) => (
  <AlgoliaProvider
    indexName={ALGOLIA_INDICES.insightsSuggestions}
    disableSearchOnEmpty
  >
    <FourOFour {...props} />
  </AlgoliaProvider>
);

export default FourOFourWithAlgolia;
