import { useState } from "react";
import { useEffectOnce } from "react-use";
import clsx from "clsx";
import { ITagFields, ITopic } from "~/@types/generated/contentful";
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";
import { ClientSide } from "~/@types";
import FlutterText from "../animations/flutterText";
import { PAGE_TRANSITION_DELAY } from "~/contexts/PageTransitionProvider";

type ColorHeroProps = {
  sysId: string;
  total?: number;
} & (
  | {
      // This basically means if one is provided, the other is optional
      topic: ClientSide<ITopic>;
      tag?: ClientSide<ITagFields>;
    }
  | {
      tag: ClientSide<ITagFields>;
      topic?: ClientSide<ITopic>;
    }
);

export default function ColorHero({ tag }: ColorHeroProps) {
  const { scrollYProgress } = useScroll();
  const [showHeading, setShowHeading] = useState(false);
  const anotherTransform = useTransform(scrollYProgress, [0, 0.12], [1, 0]);

  const sequence = () => {
    setTimeout(() => setShowHeading(true), PAGE_TRANSITION_DELAY + 300);
  };

  useEffectOnce(() => {
    sequence();
  });

  // Tags bg is slate from tailwindConfig
  const slate = "#F5F5F5";

  return (
    <section className="relative mx-auto h-full w-screen">
      <div className={clsx("z-10 flex h-screen w-screen")}>
        {/* // height is screen minus space for bottom explore eyebrow */}
        <div className="absolute left-0 top-mobile-nav flex h-[calc(100vh-44px-80px)] w-full justify-center lg:top-desktop-nav lg:mx-[36px] lg:h-[calc(100vh-44px-96px)] lg:w-[calc(100vw-72px)]">
          <AnimatePresence>
            <motion.div
              key="text"
              className="absolute inset-0 z-10 flex h-full w-full items-center justify-center"
              style={{
                opacity: anotherTransform,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.7,
              }}
            >
              <div className="absolute inset-0 z-10 flex h-full w-full flex-col justify-center">
                <div className="flex w-full flex-col items-center justify-center">
                  <p className="eyebrows col-span-full mb-6 text-center lg:col-span-4 lg:col-start-5">
                    Explore
                  </p>
                  {showHeading && tag?.title && (
                    <FlutterText
                      text={tag.title}
                      className="display col-span-full max-w-[1400px] text-center lg:col-span-8 lg:col-start-3"
                    />
                  )}
                </div>
              </div>
            </motion.div>
            <motion.div
              key="bg"
              className="absolute inset-0 flex h-full w-full items-center justify-center"
              style={{
                opacity: anotherTransform,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.7,
              }}
            >
              <div
                className="absolute inset-0 flex h-full w-full"
                style={{
                  backgroundColor: slate,
                }}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
}
