import { Portal } from "@headlessui/react";
import clsx from "clsx";
import { useFilterState } from "~/components/search/filterState";
import {
  CircleIconCta,
  CloseCta,
  IconCta,
  RoundedCta,
  TextCta,
} from "../../ctas";
import { Arrow, Filter } from "../../icons";
import { ALGOLIA_FACETS } from "~/config";
import { FacetMenu } from "~/components/search/FacetMenu";
import { getYears } from "~/components/search/SearchFilters";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import { useNavShowing } from "~/contexts/NavShowingProvider";
import { useState } from "react";
import isEqual from "lodash/isEqual";
import { AnimatePresence, motion } from "framer-motion";
import { ClientSide } from "~/@types";
import { ITopic } from "~/@types/generated/contentful";

type TopicFiltersPopoverProps = {
  topic: ClientSide<ITopic>;
};

export default function TopicFiltersPopover({
  topic,
}: TopicFiltersPopoverProps) {
  const { setNavDisabled, setNavShowing } = useNavShowing();
  const [open, _setOpen] = useState(false);
  const setOpen = (isOpen: boolean) => {
    _setOpen(isOpen);
    setNavDisabled(isOpen);
    setNavShowing(!isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  const topicTitle = topic.fields.title;

  const cleanState = {
    topics: [topicTitle],
    regions: [],
    types: [],
    years: [],
  };

  const {
    clearFilters,
    handleChange,
    handleSubmit,
    menuState,
    numFiltersChecked: _numFiltersChecked,
    numFiltersApplied,
    numResults,
  } = useFilterState({
    cleanState,
  });

  // Subtract 1 because the topic filter is not selectable in the menu
  const numFiltersChecked = _numFiltersChecked - 1;

  const instantSearch = useInstantSearch();
  // Refines results by the given topic
  if (
    instantSearch.indexUiState.refinementList?.[
      ALGOLIA_FACETS.insights.topic
    ]?.[0] !== topicTitle
  ) {
    // You might think this belongs in a `useEffect`
    // And you're probably right but the SSR doesn't
    // work properly like that.
    instantSearch.setIndexUiState((state) => ({
      ...state,
      refinementList: {
        ...state.refinementList,
        [ALGOLIA_FACETS.insights.topic]: [topicTitle],
      },
    }));
  }

  // minus 1 for the topic which is always applied
  const actualFiltersApplied = numFiltersApplied - 1;

  return (
    <>
      <CircleIconCta
        id="topic-filter-show-button"
        className="border border-slate lgmax:hidden"
        onClick={() => setOpen(!open)}
        kind="white"
        icon={<Filter />}
        label={
          actualFiltersApplied > 0
            ? `Show Filter (${actualFiltersApplied})`
            : "Show Filter"
        }
      />
      <IconCta
        kind="black"
        icon={<Filter />}
        onClick={() => setOpen(!open)}
        label={
          actualFiltersApplied > 0
            ? `Filter (${actualFiltersApplied})`
            : "Filter"
        }
        className="lg:hidden"
      />
      <Portal>
        <AnimatePresence>
          {open && (
            <motion.div
              id="topic-filters-popover"
              className="fixed inset-0 z-50 flex min-h-[425px] flex-col bg-black lg:bottom-[20vh] small-height:bottom-0"
              style={{ backgroundColor: topic.fields.color }}
              initial={{
                clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
              }}
              animate={{
                clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                transition: { duration: 0.4, ease: "easeInOut" },
              }}
              exit={{
                clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                WebkitClipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                transition: { duration: 0.4, ease: "easeInOut" },
              }}
            >
              <nav
                className="global-container flex h-min min-h-[42px] w-screen items-center justify-between px-mobile-gutter pt-9"
                style={{ backgroundColor: topic.fields.color }}
              >
                <p className="eyebrows text-white">Filter</p>
                <CloseCta
                  id="topic-filters-close"
                  kind="white"
                  onClick={() => setOpen(false)}
                />
              </nav>
              <div className="global-grid global-container h-full w-full flex-grow overflow-y-scroll pt-16">
                <FacetMenu
                  facetName={ALGOLIA_FACETS.insights.region}
                  selected={menuState.regions}
                  onChange={handleChange("regions")}
                  label="By Region"
                  classes={{
                    root: "lg:col-start-4 col-start-auto col-span-2",
                  }}
                />
                <FacetMenu
                  facetName={ALGOLIA_FACETS.insights.type}
                  selected={menuState.types}
                  onChange={handleChange("types")}
                  label="By Type"
                  classes={{
                    root: "col-start-1 lg:col-start-auto col-span-2",
                  }}
                />
                <FacetMenu
                  facetName={ALGOLIA_FACETS.insights.year}
                  selected={menuState.years}
                  onChange={handleChange("years")}
                  label="By Year"
                  customOptions={getYears()}
                  classes={{
                    root: "col-start-1 lg:col-start-auto col-span-2",
                    ul: "border-white border-[1px] border-solid max-h-60 lg:min-w-full overflow-y-scroll",
                    li: "flex-row-reverse data-[checked=true]:bg-white data-[checked=true]:text-black pl-2 pr-4 min-w-full",
                    inputLabel: "flex-grow checked:bg-white meta-large",
                    input: "invisible checked:visible invert",
                  }}
                />
              </div>
              <div className="global-container hidden w-full lg:block">
                <hr className="w-full text-white" />
              </div>
              <div
                className={clsx("w-full", `lgmax:!bg-onyx`)}
                style={{ backgroundColor: topic.fields.color }}
              >
                <div className="global-container mb-0 flex h-24 min-h-0 w-full items-center justify-between">
                  <TextCta
                    id="topic-filters-clear"
                    label={
                      numFiltersChecked
                        ? `Clear (${numFiltersChecked})`
                        : "Clear"
                    }
                    disabled={!numFiltersChecked}
                    kind="white"
                    onClick={clearFilters}
                    className={clsx({ invisible: !numFiltersChecked })}
                  />
                  <RoundedCta
                    id="topic-filters-show-cta"
                    label={
                      numResults === 0
                        ? "No Results"
                        : isEqual(cleanState, menuState)
                        ? "Apply"
                        : `Show ${numResults} Results`
                    }
                    disabled={numResults === 0}
                    kind="white"
                    icon={<Arrow />}
                    onClick={() => {
                      handleSubmit();
                      setOpen(false);
                      // Scroll to results
                      setTimeout(() => {
                        window.scrollTo({
                          behavior: "smooth",
                          top: window.innerHeight + 80,
                        });
                        // Delay to show animation and because results
                        // may take a short time to show on screen.
                      }, 500);
                    }}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {open && (
            <motion.div
              id="topic-filters-backdrop"
              className="fixed bottom-0 z-20 h-screen w-screen bg-black lgmax:hidden"
              onClick={() => setOpen(false)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
      </Portal>
    </>
  );
}
