import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IMediaHeroFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { isLastBlockEmptyParagraph } from "~/contexts/dataUtils/isLastBlockEmptyParagraph";
import { ContentfulCenteredImage, ContentfulImg } from "../shared";
import ScaleUpOnScroll from "../shared/ScaleUpOnScroll";
import { bgColorOverrides } from "../Insight/CardsGrid";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";

type MediaHeroProps = {
  fields: SpecificLocaleFields<IMediaHeroFields>;
};

export default function MediaHero({ fields }: MediaHeroProps) {
  const bodyContent = fields.body?.content;
  if (bodyContent && isLastBlockEmptyParagraph(bodyContent)) {
    bodyContent.pop();
  }

  const vimeoEmbedId = fields.videoEmbed;

  return (
    <section
      data-content-type="mediaHero"
      data-module-title={fields.moduleTitle}
      className={clsx(
        "w-screen pt-14 lg:pt-24",
        bgColorOverrides[fields.topColor],
        {
          "text-white": ["black"].includes(fields.topColor),
          "text-black": ["white", "slate"].includes(fields.topColor),
        }
      )}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className="global-container global-grid">
        <p className="eyebrows col-span-2 mb-6">{fields.eyebrow}</p>
        <h1
          className={clsx("col-span-full lg:col-span-12", {
            "mb-10 lg:lg:mb-16": !fields.body,
            "mb-6 lg:mb-6": fields.body,
          })}
        >
          <span className={clsx({ "sr-only": fields.headingLogo })}>
            {fields.heading}
          </span>

          {fields.headingLogo && (
            <ContentfulImg image={fields.headingLogo?.fields} />
          )}
        </h1>
        {fields.body && (
          <div className="body-large col-span-4 mb-6 lg:col-span-6 lg:mb-10 [&>:first-child]:mt-0">
            {documentToReactComponents(
              fields.body,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        )}
        {fields.mediaHeroCta && (
          <div className="col-span-4 lg:col-span-12">
            <RoundedCta
              className="mb-10 lg:mb-20"
              kind={fields.mediaHeroCta?.fields.type}
              label={fields.mediaHeroCta?.fields.buttonText}
              icon={<Arrow />}
              to={fields.mediaHeroCta?.fields.buttonUrl}
              custom={fields.mediaHeroCta.fields.colorPalette?.fields}
            />
          </div>
        )}
      </div>

      <div className="relative w-screen pb-14 lg:pb-24">
        <div
          className={clsx(
            "absolute bottom-0 h-[55%] w-screen",
            bgColorOverrides[fields.bottomColor],
            {
              "text-white": ["black"].includes(fields.bottomColor),
              "text-black": ["white", "slate"].includes(fields.bottomColor),
            }
          )}
          style={{ backgroundColor: fields.colorPalette?.fields.accentColor }}
        />
        <div className="global-container relative col-span-4 lg:col-span-12">
          <div
            className={clsx("h-full overflow-hidden", {
              "aspect-3/4 lg:aspect-video": !vimeoEmbedId,
              "aspect-video": vimeoEmbedId,
            })}
          >
            {vimeoEmbedId ? (
              <>
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <iframe
                    title="Vimeo video player"
                    src={`${vimeoEmbedId}?h=1ac4fd9fb4&byline=0&portrait=0`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </>
            ) : (
              <ScaleUpOnScroll>
                <ContentfulCenteredImage
                  image={fields.image?.fields ?? ""}
                  className="aspect-3/4 h-full lg:aspect-video"
                />
              </ScaleUpOnScroll>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
