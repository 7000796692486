import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IFigure,
  SpecificLocale,
  SpecificLocaleFields,
  IGridCardsFields,
  IComparisonChartFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import {
  assertComparisonChart,
  assertGridCards,
  assertHtmlString,
  assertImage,
  assertTable,
} from "~/contexts/dataUtils/assertions";
import { Table, CardsGrid, ComparisonChart, HtmlString } from ".";
import { ContentfulImg } from "../shared";
import CenteredImgZoomInOnView from "../shared/CenteredImgZoomInOnView";

type FigureProps = {
  figure: SpecificLocale<IFigure>;
  beforeSecondChapter?: boolean;
};

// Basically just remove the "Figure #:" from the old string.
// This is an artifact from the transfer from the old site
const getCleanFigureHeading = (heading: string) => {
  if (heading.startsWith("Figure")) {
    const splits = heading.split(":");
    if (splits[1] && splits[1].length) {
      return splits.slice(1).join(":");
    }
  }
  return heading;
};

export default function Figure({
  figure,
  beforeSecondChapter = false,
}: FigureProps) {
  const { figures } = useComponentData() as InsightComponentData;

  const figNum = figures?.[figure.sys.id]?.number || null;

  // If there's no figNum, this is not part of the figures componentData
  /// So therefore, we are ignoring it in `findAllFigures` on purpose
  if (!figNum) return null;

  const figItem = figure.fields.item;

  const isVideo =
    figItem && assertHtmlString(figItem) && figItem.fields.type === "video";

  const fullWidth =
    figure.fields.graphicWidth === "Full Width" || isVideo ? true : false;

  const fallbackImage = figure.fields.fallbackImage;

  // If there is no figure item and no fallback image don't render this
  if (!figItem && !fallbackImage) return null;

  return (
    <section
      data-content-type="figure"
      data-module-title={figure.fields.moduleTitle}
      className={clsx(
        "global-grid relative col-span-full mt-10",
        fullWidth && "full-width"
      )}
    >
      <div
        className={clsx({
          "article-columns": !fullWidth,
          "article-columns-max": fullWidth,
        })}
      >
        <p className="meta mt-10 mb-6 text-secondary_text">Figure {figNum}</p>
        {figure.fields.richTextHeading ? (
          <div className="h4">
            {documentToReactComponents(
              figure.fields.richTextHeading,
              getComponentFromContentfulRichTextEntry
            )}
          </div>
        ) : (
          figure.fields.heading && (
            <h4>{getCleanFigureHeading(figure.fields.heading)}</h4>
          )
        )}
      </div>
      {figure.fields.body && (
        <div
          className={clsx("[&_p]:body pb-10", {
            "article-columns-max": fullWidth,
            "article-columns": !fullWidth,
          })}
        >
          {documentToReactComponents(
            figure.fields.body,
            getComponentFromContentfulRichTextEntry
          )}
        </div>
      )}
      {figItem && figItem.sys.contentType ? (
        <div
          className={clsx("[&>:first-child]:px-0", {
            "article-columns-max": fullWidth,
            "article-columns": !fullWidth,
          })}
        >
          {/* IMAGE */}
          {assertImage(figItem) && (
            <CenteredImgZoomInOnView
              image={figItem.fields}
              className="h-full w-full"
              once
            />
          )}

          {/* HTMLString */}
          {assertHtmlString(figItem) && <HtmlString fields={figItem.fields} />}

          {/* TABLE */}
          {assertTable(figItem) && <Table fields={figItem.fields} />}

          {/* GRID CARDS */}
          {assertGridCards(figItem) && (
            <CardsGrid
              fields={figItem.fields as SpecificLocaleFields<IGridCardsFields>}
            />
          )}

          {/* COMPARISON CHART */}
          {assertComparisonChart(figItem) && (
            <ComparisonChart
              fields={
                figItem.fields as SpecificLocaleFields<IComparisonChartFields>
              }
            />
          )}
        </div>
      ) : fallbackImage ? (
        <ContentfulImg
          image={fallbackImage.fields}
          className={clsx("h-auto w-full", {
            "article-columns-max": fullWidth,
            "article-columns": !fullWidth,
          })}
        />
      ) : null}
      {figure.fields.caption && (
        <div
          className={clsx("[&_p]:body-small [&_p]:text-secondary_text", {
            "article-columns-max": fullWidth,
            "article-columns": !fullWidth,
          })}
        >
          {documentToReactComponents(
            figure.fields.caption,
            getComponentFromContentfulRichTextEntry
          )}
        </div>
      )}
      {beforeSecondChapter || (figItem && assertTable(figItem)) ? (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      ) : null}
    </section>
  );
}
