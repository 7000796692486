import type { Options } from "@contentful/rich-text-react-renderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import type { Document, TopLevelBlock } from "@contentful/rich-text-types";
import { INLINES } from "@contentful/rich-text-types";
import { BLOCKS } from "@contentful/rich-text-types";
import { assertUnreachable } from "~/utils";
import type {
  CONTENT_TYPE,
  Asset,
  SpecificLocale,
  SpecificLocaleFields,
  ITextSplitFields,
  IVideoHeroFields,
  IThreeUpRoutingFields,
  IStatementRoutingFields,
  ITopicCarouselFields,
  IFiftyFiftyCarouselFields,
  INewsletterFields,
  IMediaHeroFields,
  IIndentedTextFields,
  ITwoColumnMediaFields,
  IStatisticsRowFields,
  IPartnerRoutingFields,
  ISingleColumnMediaFields,
  IImageCarouselFields,
  IInsightsListFields,
  IFeaturedCarouselFields,
  IFeaturedInsight,
  IExpertsHeroFields,
  IExpertsListFields,
  IExpertsCarouselFields,
  IBoxoutFields,
  IPage,
  IInsight,
  IPullQuoteFields,
  ITwitterEmbedFields,
  IChapterHeading,
  IPageRoutingFields,
  IComparisonChartFields,
  IGridCardsFields,
  IFigure,
  IHtmlStringFields,
  IDownloadListFields,
  ITableFields,
  IHeroWithImageFields,
  ITextOnlyHeroFields,
  IFiftyFiftyFields,
  IEventListFields,
  IFootnote,
  IVideoPlayerFields,
  ITextBlockFields,
  IRoundedCtaButtonFields,
  IInsightColumnCardsFields,
  IExpertHeroFields,
  IExpert,
  IImageFields,
  ISingleImageParallaxFields,
  IDualParallaxFields,
  ICaseStudy,
  IHomeVideoHeroFields,
  IExpertFiftyFiftyFields,
  IPartnership,
  IMediaTabsFields,
  IAnchorLinksFields,
  IExpertsSectionsFields,
  ITwoUpRoutingFields,
} from "~/@types/generated/contentful";
import {
  BlockQuote,
  Boxout,
  CardsGrid,
  CaseStudy,
  ChapterHeading,
  ComparisonChart,
  Figure,
  Footnote,
  HtmlString,
  PullQuote,
  Table,
  TableBlock,
  TableCell,
  TableRow,
  TwitterEmbed,
} from "~/components/Insight";
import {
  ContentfulAsset,
  ContentfulCenteredImage,
  DualParallax,
  ForcedAspectRatio,
  VideoPlayer,
  StatisticsRow,
} from "~/components/shared";
import {
  InsightsListingHero,
  VideoHero,
  MediaHero,
  ReducedHeroTextOnly,
  ReduceHeroWithImage,
  HomeVideoHero,
} from "~/components/heros";
import FiftyFiftyTextSplitComponent from "~/components/page/FiftyFiftyTextSplit";
import {
  DownloadList,
  EventsRouting,
  ExpertFiftyFifty,
  FeaturedInsight,
  FiftyFifty,
  FiftyFiftyCarousel,
  ImageGalleryCarousel,
  IndentedText,
  InsightColumnCards,
  MediaTabs,
  PageRouting,
  PartnerRouting,
  RoundedCtaItem,
  SingleColumn,
  StatementRouter,
  TextBlock,
  ThreeUpRouting,
  TopicCarousel,
  TwoColumn,
} from "~/components/page";
import NewsletterComponent from "~/components/shared/newsletter/Newsletter";
import { CardList, InsightColorCarousel } from "~/components/Cards";
import {
  ExpertHero,
  ExpertsCarousel,
  ExpertsHero,
  ExpertsList,
} from "~/components/experts";
import { ClientSide } from "~/@types";
import SingleImageParallax from "~/components/shared/parallax/SingleImageParallax";
import { Paragraph } from "~/components/Insight/Elements";
import NavOrExternalLink from "~/components/shared/NavOrExternalLink";
import AnchorLinks from "~/components/page/AnchorLinks";
import ExpertsSections from "~/components/experts/ExpertSections";
import TwoUpRouting from "~/components/page/TwoUpRouting";

/**
 * This function returns components generated from a Contentful Rich Text entry.
 */
export const getComponentFromContentfulRichTextEntry: Options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const contentType: CONTENT_TYPE = node.data.target.sys.contentType.sys.id;
      if (contentType === "footnote") {
        const footnote = node.data.target as SpecificLocale<IFootnote>;
        return <Footnote footnote={footnote} />;
      } else if (contentType === "lineBreak") {
        return <br />;
      } else {
        // So far footnote is the only inline entry type we use
        return null;
      }
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <NavOrExternalLink
          to={node.data.uri}
          className="underline hover:no-underline"
        >
          {children}
        </NavOrExternalLink>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (
        <NavOrExternalLink to={getEntryPath(node.data.target)}>
          {children}
        </NavOrExternalLink>
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="article-columns relative pt-10">
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="article-columns relative pt-10">
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="article-columns relative pt-10">
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="article-columns relative pt-10">
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="article-columns relative pt-10">
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="article-columns relative pt-10">
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </h6>
    ),
    [BLOCKS.HR]: (node /* assume no children */) => (
      <div className="article-columns relative">
        <hr className="border border-solid border-[currentColor]" />
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </div>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul
        className={
          "article-columns ul-body relative pt-10 [&_li]:relative [&_li]:pl-2"
        }
      >
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className={"article-columns ol-body relative list-decimal pt-10"}>
        {children}
        {node.data.beforeSecondChapter && (
          <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
        )}
      </ol>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (children?.toString() !== "") {
        return <Paragraph node={node}>{children}</Paragraph>;
      }
    },
    [BLOCKS.QUOTE]: (node, children) => <BlockQuote>{children}</BlockQuote>,
    [BLOCKS.TABLE]: (node, children) => <TableBlock>{children}</TableBlock>,
    [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
      <TableCell header={true} node={node}>
        {children}
      </TableCell>
    ),
    [BLOCKS.TABLE_CELL]: (node, children) => (
      <TableCell
        contentLength={documentToPlainTextString(node).length}
        header={false}
        node={node}
      >
        {children}
      </TableCell>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const asset = node.data.target as SpecificLocale<Asset>;
      return <ContentfulAsset asset={asset} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      // This happens if someone deletes an entry without also removing it from it's parent
      if (!node.data.target.sys.hasOwnProperty("contentType")) return null;

      const contentType: CONTENT_TYPE = node.data.target.sys.contentType.sys.id;
      // =======================================
      // +++++++++++++++++++++++++++++++++++++++
      //           INSIGHT COMPONENTS
      // +++++++++++++++++++++++++++++++++++++++
      // =======================================
      if (contentType === "pullQuote") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IPullQuoteFields>;
        return (
          <PullQuote
            fields={fields}
            beforeSecondChapter={node.data.beforeSecondChapter}
          />
        );
      } else if (contentType === "boxout") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IBoxoutFields>;
        return (
          <Boxout
            fields={fields}
            beforeSecondChapter={node.data.beforeSecondChapter}
          />
        );
      } else if (contentType === "table") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITableFields>;
        return <Table fields={fields} />;
      } else if (contentType === "htmlString") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IHtmlStringFields>;
        return (
          <HtmlString
            fields={fields}
            beforeSecondChapter={node.data.beforeSecondChapter}
          />
        );
      } else if (contentType === "twitterEmbed") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITwitterEmbedFields>;
        return (
          <TwitterEmbed
            fields={fields}
            beforeSecondChapter={node.data.beforeSecondChapter}
          />
        );
      } else if (contentType === "caseStudy") {
        const caseStudy = node.data.target as SpecificLocale<ICaseStudy>;
        return <CaseStudy caseStudy={caseStudy} />;
      } else if (contentType === "gridCards") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IGridCardsFields>;
        return (
          <CardsGrid
            fields={fields}
            beforeSecondChapter={node.data.beforeSecondChapter}
          />
        );
      } else if (contentType === "figure") {
        const figure = node.data.target as SpecificLocale<IFigure>;
        return (
          <Figure
            figure={figure}
            beforeSecondChapter={node.data.beforeSecondChapter}
          />
        );
      } else if (contentType === "chapterHeading") {
        const chapter = node.data.target as SpecificLocale<IChapterHeading>;
        return (
          <ChapterHeading sysId={node.data.target.sys.id} chapter={chapter} />
        );
        // =======================================
        // +++++++++++++++++++++++++++++++++++++++
        //           SHARED ITEMS
        // +++++++++++++++++++++++++++++++++++++++
        // =======================================
      } else if (contentType === "singleImageParallax") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ISingleImageParallaxFields>;
        return <SingleImageParallax fields={fields} />;
      } else if (contentType === "dualParallax") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IDualParallaxFields>;
        return <DualParallax fields={fields} />;

        // =======================================
        // +++++++++++++++++++++++++++++++++++++++
        //           PAGE ITEMS
        // +++++++++++++++++++++++++++++++++++++++
        // =======================================
      } else if (contentType === "roundedCtaButton") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IRoundedCtaButtonFields>;
        return <RoundedCtaItem fields={fields} />;
      } else if (contentType === "image") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IImageFields>;
        return (
          <ForcedAspectRatio
            ratio={[
              fields.aspectRatioWidth ?? 16,
              fields.aspectRatioHeight ?? 9,
            ]}
            className="article-columns relative w-full"
          >
            <ContentfulCenteredImage image={fields} className="h-full w-full" />
            {node.data.beforeSecondChapter && (
              <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
            )}
          </ForcedAspectRatio>
        );

        // =======================================
        // +++++++++++++++++++++++++++++++++++++++
        //           PAGE COMPONENTS
        // +++++++++++++++++++++++++++++++++++++++
        // =======================================
      } else if (contentType === "textSplit") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITextSplitFields>;
        return <FiftyFiftyTextSplitComponent fields={fields} />;
      } else if (contentType === "comparisonChart") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IComparisonChartFields>;
        return (
          <ComparisonChart
            fields={fields}
            beforeSecondChapter={node.data.beforeSecondChapter}
          />
        );
      } else if (contentType === "threeUpRouting") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IThreeUpRoutingFields>;
        return <ThreeUpRouting fields={fields} />;
      } else if (contentType === "twoUpRouting") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITwoUpRoutingFields>;
        return <TwoUpRouting fields={fields} />;
      } else if (contentType === "statementRouting") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IStatementRoutingFields>;
        return <StatementRouter fields={fields} />;
      } else if (contentType === "topicCarousel") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITopicCarouselFields>;
        return <TopicCarousel fields={fields} />;
      } else if (contentType === "fiftyFiftyCarousel") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IFiftyFiftyCarouselFields>;
        return <FiftyFiftyCarousel fields={fields} />;
      } else if (contentType === "newsletter") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<INewsletterFields>;
        return <NewsletterComponent fields={fields} />;
      } else if (contentType === "indentedText") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IIndentedTextFields>;
        return <IndentedText fields={fields} />;
      } else if (contentType === "twoColumnMedia") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITwoColumnMediaFields>;
        return <TwoColumn fields={fields} />;
      } else if (contentType === "singleColumnMedia") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ISingleColumnMediaFields>;
        return <SingleColumn fields={fields} />;
      } else if (contentType === "statisticsRow") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IStatisticsRowFields>;
        return <StatisticsRow fields={fields} />;
      } else if (contentType === "textBlock") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITextBlockFields>;
        return <TextBlock fields={fields} />;
      } else if (contentType === "partnerRouting") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IPartnerRoutingFields>;
        return <PartnerRouting fields={fields} />;
      } else if (contentType === "pageRouting") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IPageRoutingFields>;
        return <PageRouting fields={fields} />;
      } else if (contentType === "imageCarousel") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IImageCarouselFields>;
        return <ImageGalleryCarousel fields={fields} />;
      } else if (contentType === "downloadList") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IDownloadListFields>;
        return <DownloadList fields={fields} />;
      } else if (contentType === "featuredCarousel") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IFeaturedCarouselFields>;
        return <InsightColorCarousel fields={fields} />;
      } else if (contentType === "expertsCarousel") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IExpertsCarouselFields>;
        return (
          <ExpertsCarousel fields={fields} sysId={node.data.target.sys.id} />
        );
      } else if (contentType === "expertsList") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IExpertsListFields>;
        return <ExpertsList fields={fields} sysId={node.data.target.sys.id} />;
      } else if (contentType === "expertsSections") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IExpertsSectionsFields>;
        return (
          <ExpertsSections fields={fields} sysId={node.data.target.sys.id} />
        );
      } else if (contentType === "insightsList") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IInsightsListFields>;
        return (
          <CardList
            insightsListFields={fields}
            relatedEntry={node.data.target}
          />
        );
      } else if (contentType === "fiftyFifty") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IFiftyFiftyFields>;
        return <FiftyFifty fields={fields} />;
      } else if (contentType === "expertFiftyFifty") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IExpertFiftyFiftyFields>;
        return <ExpertFiftyFifty fields={fields} />;
      } else if (contentType === "videoPlayer") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IVideoPlayerFields>;
        return <VideoPlayer fields={fields} />;
      } else if (contentType === "eventList") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IEventListFields>;
        return <EventsRouting fields={fields} />;
      } else if (contentType === "insightColumnCards") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IInsightColumnCardsFields>;
        return <InsightColumnCards fields={fields} />;
      } else if (contentType === "expertHero") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IExpertHeroFields>;
        return <ExpertHero expert={fields.expert as ClientSide<IExpert>} />;
      } else if (contentType === "featuredInsight") {
        // Don't do fields because we need the target.sys.id in order to grab the right object off componentData context
        const insight = node.data.target as SpecificLocale<IFeaturedInsight>;
        return <FeaturedInsight insight={insight} />;
        // =======================================
        // +++++++++++++++++++++++++++++++++++++++
        //           HEROS
        // +++++++++++++++++++++++++++++++++++++++
        // =======================================
      } else if (contentType === "expertsHero") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IExpertsHeroFields>;
        return <ExpertsHero fields={fields} />;
      } else if (contentType === "heroWithImage") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IHeroWithImageFields>;
        return <ReduceHeroWithImage fields={fields} />;
      } else if (contentType === "textOnlyHero") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ITextOnlyHeroFields>;
        return <ReducedHeroTextOnly fields={fields} />;
      } else if (contentType === "insightsHero") {
        const fields = node.data.target.fields;
        return <InsightsListingHero fields={fields} />;
      } else if (contentType === "videoHero") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IVideoHeroFields>;
        return <VideoHero fields={fields} />;
      } else if (contentType === "homeVideoHero") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IHomeVideoHeroFields>;
        return <HomeVideoHero fields={fields} />;
      } else if (contentType === "mediaHero") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IMediaHeroFields>;
        return <MediaHero fields={fields} />;
      } else if (contentType === "mediaTabs") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IMediaTabsFields>;
        return <MediaTabs fields={fields} />;
      } else if (contentType === "anchorLinks") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IAnchorLinksFields>;
        return <AnchorLinks fields={fields} />;
      } else if (
        // These are types that don't get rendered on the page and are not components in this repo
        // Pages
        contentType === "page" ||
        contentType === "expert" ||
        contentType === "threeUpRoute" ||
        contentType === "insight" ||
        contentType === "topic" ||
        contentType === "ticker" ||
        contentType === "tag" ||
        contentType === "redirect" ||
        // TODO: Partnerships... duh
        contentType === "partnership" ||
        // Items
        contentType === "carouselItem" ||
        contentType === "downloadItem" ||
        contentType === "eventItem" ||
        contentType === "statisticsBlock" ||
        contentType === "socialMedia" ||
        contentType === "boxoutItem" ||
        contentType === "pageRoute" ||
        contentType === "comparisonRow" ||
        contentType === "gridCard" ||
        contentType === "lineBreak" ||
        contentType === "footnote" ||
        contentType === "mediaTab" ||
        contentType === "anchorLink" ||
        // Settings
        contentType === "settings"
      ) {
        // This is intentionally empty to capture content types that we know exist and are confident will never be embedded in a rich text document
        // It's here to make the assertUnreachable(contentType) below help us catch new content types and either add to this list, or handle appropriately above
      } else {
        // @ts-ignore-next-line
        assertUnreachable(contentType);
      }
    },
  },
};

type SectionType = "full" | "inset";

export interface Section {
  type: SectionType;
  nodes: TopLevelBlock[];
}

// This is used to separate parts of a rich text field into sections so we can wrap them differently (inset on the page vs. full width, etc.)
export function getSections(document: Document): Section[] {
  const sectionTypes: { [key in BLOCKS]?: SectionType } = {
    [BLOCKS.EMBEDDED_ENTRY]: "full",
  };
  const defaultSectionType: SectionType = "inset";
  return document.content.reduce<Section[]>((sections, node) => {
    // Figure out what section type we're aiming for for this node
    const targetSectionType = sectionTypes[node.nodeType] || defaultSectionType;
    // Get the last element of our sections array for the current section
    const section = sections[sections.length - 1];
    if (section && section.type === targetSectionType) {
      // We have a section and it matches our target type, so add our node
      section.nodes.push(node);
    } else {
      // We either don't have a section at all, or it doesn't match our target type, so create a new one with our node in it
      sections.push({
        type: targetSectionType,
        nodes: [node],
      });
    }
    return sections;
  }, []);
}

export function createDocumentFromSection(section: Section): Document {
  return {
    content: section.nodes,
    nodeType: BLOCKS.DOCUMENT,
    data: {},
  };
}

export function getEntryPath(
  entry:
    | SpecificLocale<IPage>
    | SpecificLocale<IInsight>
    | SpecificLocale<IExpert>
    | SpecificLocale<IPartnership>
): string {
  const contentType: CONTENT_TYPE = entry.sys.contentType.sys.id;
  if (contentType === "page") {
    return getPagePath(entry as SpecificLocale<IPage>);
  } else if (contentType === "insight") {
    return getInsightPath(entry as SpecificLocale<IInsight>);
  } else if (["partnership", "expert"].includes(contentType)) {
    return getSimplePath(contentType, entry.fields.slug);
  } else {
    throw new Error("tried to get path to an unhandled entry type");
  }
}

function getPagePath(page: SpecificLocale<IPage>): string {
  return `${page.fields.slugPrefix ? "/" + page.fields.slugPrefix : ""}/${
    page.fields.slug
  }`;
}

function getInsightPath(insight: SpecificLocale<IInsight>): string {
  return `/insights/${insight.fields.topic?.fields.slug}/${insight.fields.slug}`;
}

function getSimplePath(prefix: string, slug: string): string {
  return `/${prefix}/${slug}`;
}
