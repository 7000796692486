import { RoundedCta } from "~/components/ctas";
import { Arrow } from "~/components/icons";
import { countries, sectors } from "./values";
import clsx from "clsx";
import { FormEventHandler } from "react";
import { Form } from "@remix-run/react";

interface NewsletterFormProps {
  onSubmit: FormEventHandler;
}

export default function NewsletterForm({ onSubmit }: NewsletterFormProps) {
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <Form
      className="mb-16 h-full w-full"
      onSubmit={handleSubmit}
      id="newsletter_form"
    >
      <h5 className="mb-10">
        Be the first to know what we're doing - and how you can get more
        involved.
      </h5>
      <label htmlFor="newsletter_first_name" className="flex flex-col-reverse">
        <input
          id="newsletter_first_name"
          name="newsletter_first_name"
          autoFocus={true}
          type={"text"}
          required
          defaultValue=""
          placeholder="First name *"
          className={clsx(
            "body border-b-1 col-span-4 mb-6 w-full border-0 border-b border-solid border-b-black py-1 text-black outline-0 outline-offset-4 outline-slate placeholder:text-secondary_text focus:outline-1 lg:col-start-2"
          )}
        />
        <span className="meta-small text-secondary_text opacity-0 transition-opacity peer-focus:opacity-100">
          First Name *
        </span>
      </label>
      <label htmlFor="newsletter_last_name" className="flex flex-col-reverse">
        <input
          id="newsletter_last_name"
          name="newsletter_last_name"
          autoFocus={true}
          type={"text"}
          required
          defaultValue=""
          placeholder="Last name *"
          className={clsx(
            "body border-b-1 col-span-4 mb-6 w-full border-0 border-b border-solid border-b-black py-1 text-black outline-0 outline-offset-4 outline-slate placeholder:text-secondary_text focus:outline-1 lg:col-start-2"
          )}
        />
        <span className="meta-small text-secondary_text opacity-0 transition-opacity peer-focus:opacity-100">
          Last Name *
        </span>
      </label>
      <input
        type={"email"}
        id="newsletter_email"
        name="newsletter_email"
        className="body border-b-1 col-span-4 mb-6 w-full border-0 border-b border-solid border-b-black py-1 text-black outline-0 outline-offset-4 outline-slate placeholder:text-secondary_text focus:outline-1 lg:col-start-2"
        defaultValue=""
        placeholder="Email *"
        required
      />
      <select
        aria-required="true"
        id="newsletter_sector"
        name="newsletter_sector"
        className="body col-span-4 mb-6 w-full border border-x-0 border-t-0 border-solid border-b-black py-1 text-secondary_text outline-offset-4 outline-slate placeholder:text-secondary_text valid:text-black lg:col-start-2"
        defaultValue=""
        aria-label="Sector"
        required
      >
        <option value="">Sector *</option>
        {sectors.map(({ name, value }) => (
          <option value={value} key={value}>
            {name}
          </option>
        ))}
      </select>
      <select
        className="body col-span-4 w-full border border-x-0 border-t-0 border-solid border-b-black py-1 text-secondary_text outline-offset-4 outline-slate placeholder:text-secondary_text valid:text-black lg:col-start-2 [&>*]:bg-black"
        id="newsletter_country"
        name="newsletter_country"
        defaultValue=""
        aria-label="Country"
        required
      >
        <option value="">Country *</option>
        {countries.map(({ name, value }) => (
          <option value={value} key={value}>
            {name}
          </option>
        ))}
      </select>
      <select
        id="newsletter_contact_type"
        name="newsletter_contact_type"
        className="hidden"
        required
      >
        <option selected>public</option>
      </select>
      <div className="my-10 flex items-start">
        <input
          type={"checkbox"}
          id="newsletter_consent"
          name="newsletter_consent"
          className="mr-3 border border-solid border-black"
        />
        <label htmlFor="newsletter_consent" className="meta-small">
          Yes, I agree to receive emails about the work of Tony Blair and the
          Tony Blair Institute. For full information on the use of your data
          please see our privacy policy.
        </label>
      </div>
      <RoundedCta kind="black" label="Sign Up" type="submit" icon={<Arrow />} />
    </Form>
  );
}
